import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { checkEffects } from 'stores/check';
import { initEvents } from 'stores/init';
import { CheckProductContentProps, WOMInitialParams } from 'types/plugin';
import { App } from './App';

// const pluginElementId = 'wom-viewer-plugin';
// const rootElement = document.getElementById(pluginElementId);

// if (process.env.NODE_ENV === 'production') {
window.wom = {
    check: async (params: CheckProductContentProps | CheckProductContentProps[]) => {
        if (Array.isArray(params)) {
            const promises = params.map(itemParams => checkEffects.checkProductContent(itemParams));

            const results = await Promise.allSettled(promises);

            const arr = results.map(({ value }: any) => {
                const { returnedRecords, selector, message } = value;

                if (value && returnedRecords) {
                    return { isSuccess: true, message: 'success', selector };
                } else {
                    if (value && returnedRecords === 0) {
                        return {
                            isSuccess: false,
                            message: 'There were no videos found',
                            selector
                        };
                    } else {
                        return {
                            isSuccess: false,
                            message: message || 'There was a problem',
                            selector: selector
                        };
                    }
                }
            });

            return arr;
        } else {
            const result = await checkEffects.checkProductContent(params);

            const { returnedRecords, selector, message } = result;

            if (result && returnedRecords) {
                return [{ isSuccess: true, message: 'success', selector }];
            } else {
                if (result && returnedRecords === 0) {
                    return [{ isSuccess: false, message: 'There were no videos found', selector }];
                } else {
                    return [{ isSuccess: false, message: message || 'There was a problem', selector }];
                }
            }
        }
    },
    init: (params: WOMInitialParams | WOMInitialParams[]) => {
        if (Array.isArray(params)) {
            params.forEach(instance => {
                const { isDev, ...appParams } = instance;
                const renderElement = document.querySelector<HTMLElement>(appParams.selector);

                initEvents.setSelector(appParams.selector);

                ReactDOM.render(
                    <StrictMode>
                        <App
                            height={renderElement ? renderElement.style.height : '100%'}
                            isDev={isDev}
                            {...appParams}
                        />
                    </StrictMode>,
                    renderElement
                );
            });
        } else {
            const { isDev, ...appParams } = params;
            const renderElement = document.querySelector<HTMLElement>(appParams.selector);

            initEvents.setSelector(appParams.selector);

            if (renderElement) {
                ReactDOM.render(
                    <StrictMode>
                        <App
                            height={renderElement ? renderElement.style.height : '100%'}
                            isDev={isDev}
                            {...appParams}
                        />
                    </StrictMode>,
                    renderElement
                );
            }
        }
    }
};
// } else {
//     initEvents.setSelector('#wom-viewer-plugin');

//     ReactDOM.render(
//         <StrictMode>
//             {/*Mock data*/}
//             <App
//                 color="red"
//                 height={rootElement ? rootElement.style.height : '100%'}
//                 isDev={false}
//                 organizationPublicId="INCODEWETRUST1_60950f88936aed152f526fc2"
//                 remoteProductId="6698870276282"
//                 selector="#wom-viewer-plugin"
//                 textColor="white"
//             />
//         </StrictMode>,
//         rootElement
//     );
// }

let event = new Event('wom-viewer-init', { bubbles: true });
document.body.dispatchEvent(event);
