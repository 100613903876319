import {
    wrapperBorderRadius,
    wrapperHeight,
    wrapperHorizontalPadding,
    wrapperMargin
} from 'components/common/Hashtag/constants';
import { green } from 'constants/styles/colors';
import { adaptiveScrollbarMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';
import { MarginBottom } from 'types/styles';

export interface WrapperProps extends MarginBottom {
    removeIcon?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    ${flexCenter};
    height: ${wrapperHeight};
    border-radius: ${wrapperBorderRadius};
    background-color: ${green};
    font-family: SF UI Text;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    margin-left: ${wrapperMargin};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
    padding: 0 ${wrapperHorizontalPadding};

    :first-child {
        margin-left: 0;
    }
`;

export const HashtagsWrapper = styled.div`
    width: 100%;
    display: flex;
    overflow-x: auto;
    ${adaptiveScrollbarMixin};
`;
