import { createEffect, createEvent, createStore, Effect } from 'effector';

interface Notification {
    message: string;
    description: string;
    id: number;
}

const incrementUuid = createEvent();
const uuid = createStore(0).on(incrementUuid, state => state + 1);

const removeNotification = createEvent<number>();
const setNotificationEvent = createEvent<Notification>();

const notifications = createStore<Notification[]>([])
    .on(setNotificationEvent, (state, notification) => [...state, notification])
    .on(removeNotification, (state, notificationId) => state.filter(({ id }) => id !== notificationId));

const setNotification = (notification: Omit<Notification, 'id'>) => {
    const id = uuid.getState();
    incrementUuid();

    setNotificationEvent({
        ...notification,
        id
    });

    (() =>
        new Promise(resolve => {
            setTimeout(resolve, 3000);
        }))().then(() => removeNotification(id));
};

interface BadResponseErrors
    extends WOM.Error409ConflictResponse,
        WOM.Error400BadRequest,
        WOM.Error404NotFoundResponse {}

export function createNotifyingEffect<Params, Done>(config: {
    name?: string;
    handler?: (params: Params) => Promise<Done> | Done;
    sid?: string;
}): Effect<Params, Done> {
    const effect = createEffect(config);

    effect.fail.watch(({ error }: { error: BadResponseErrors }) => {
        if (!error.isSuccess) {
            let message = error.message;

            if (!message || message === 'unknown') {
                message = 'Some error occurred. Please try again.';
            }

            setNotification({
                message,
                description: ''
            });
        }
    });

    return effect;
}

export const notificationsStore = { notifications };
export const notificationsEffects = { setNotification, removeNotification };
