import { Hashtags } from 'components/common/Hashtag';
import { VideoProductText } from 'components/common/videoComponents/VIdeoFooter/styles';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import React from 'react';

// TODO: api
export const VideoFooter = () => {
    //MOCK
    const hashtags: string[] = [];
    // const hashtags: string[] = ['test', 'test'];

    return (
        <Column width="100%">
            <Section marginBottom="10px">
                <VideoProductText></VideoProductText>
            </Section>
            {hashtags.length > 0 && <Hashtags hashtags={hashtags} />}
        </Column>
    );
};
