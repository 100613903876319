import {
    infoWrapperHorizontalMargin,
    infoWrapperVerticalMargin
} from 'components/common/videoComponents/VideoPlayer/constants';
import { scrollBarMobileMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

interface Props {
    backgroundColor?: string;
    textColor?: string;
}

export const InfoWrapper = styled.div`
    position: absolute;
    top: ${infoWrapperVerticalMargin};
    left: ${infoWrapperHorizontalMargin};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 2 * ${infoWrapperHorizontalMargin});
    height: calc(100% - 2 * ${infoWrapperVerticalMargin});
    z-index: 11;
`;

export const VideoPlay = styled.video`
    width: 100%;
    height: ${({ height }) => (height ? height : '100%')};
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
`;

export const VideoPlayerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const ScrolledWrapper = styled.div`
    padding: 0 30px;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
    width: 100%;
    height: 60px;
    ${scrollBarMobileMixin}
    box-sizing: border-box;
`;

export const HashtagsAbsoluteWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: -13px;
    z-index: 100;
    width: 100%;
`;

export const HashtagWrapper = styled.div<Props>`
    display: inline-block;
    padding: 0 12px;
    padding-top: 5px;
    height: 30px;
    background-color: ${({ backgroundColor }) => backgroundColor || '#00ff44'};
    border-radius: 15px;
    margin-right: 12px;
    box-sizing: border-box;
    white-space: nowrap;

    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    color: ${({ textColor }) => textColor || 'black'};
`;
