import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { WOMInitialParams } from 'types/plugin';

const getProductContent = createEffect({
    handler: async ({ remoteProductId, organizationPublicId, selector, color, textColor }: WOMInitialParams) => {
        const { items, ...data } = await API.product.getProductContent({
            remoteProductId,
            organizationPublicId,
            pageIndex: 0,
            limit: 99
        });

        const updatedItems = items?.map((item: any) => {
            item.isMuted = false;
            return item;
        });

        let obj: Record<string, any> = {};
        obj[remoteProductId] = { selector, color, textColor, items: updatedItems, ...data };

        return obj;
    }
});

const updateIsMuted = createEvent<any>();

const productContent = createStore<any>({});

productContent
    .on(getProductContent.doneData, (state, newState) => ({ ...state, ...newState }))
    .on(updateIsMuted, (state, newState) => {
        const { remoteProductId, index } = newState;
        let obj = { ...state };
        obj[remoteProductId].items[index].isMuted = !obj[remoteProductId].items[index].isMuted;
        return obj;
    });

export const productEffects = { getProductContent };
export const productStores = { productContent };
export const productEvents = { updateIsMuted };
