import loaderImg from 'assets/img/bullz_animation.gif';
import React from 'react';
import { loaderHeight, loaderWidth } from './constants';
import { Img, MobileWrapper } from './styles';

export const MobileLoader = () => (
    <MobileWrapper>
        <Img height={loaderHeight} src={loaderImg} width={loaderWidth} />
    </MobileWrapper>
);
