import { heightToWidthVideoRatio } from 'constants/video';

export const getHeightAndWidthVideo = (Video: Element) => {
    const heightParentBlock = Video.clientHeight;
    const widthParentBlock = Video.clientWidth;
    const width = heightParentBlock / heightToWidthVideoRatio;

    return [
        (heightParentBlock / widthParentBlock < heightToWidthVideoRatio
            ? heightParentBlock
            : widthParentBlock * heightToWidthVideoRatio
        ).toString(),
        width.toString()
    ];
};
