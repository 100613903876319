import { Wrapper } from 'components/wrappers/ApplicationWrapper/styles';
import { appVersion, womDevBaseURL, womProdBaseURL } from 'constants/services';
import { useStore } from 'effector-react';
import { Main } from 'pages/Main';
import React, { useEffect } from 'react';
import { globalEvents } from 'stores/global';
import { productEffects, productStores } from 'stores/product';
import { sizesEvents } from 'stores/sizes';
import { WOMInitialParams } from 'types/plugin';

export const App = ({ isDev, height, ...queryProps }: WOMInitialParams) => {
    const store = useStore(productStores.productContent);

    let { remoteProductId, organizationPublicId } = queryProps;
    sizesEvents.setHeightString(height ? height : '100%');

    useEffect(() => {
        globalEvents.setWOMBaseURL(isDev ? womDevBaseURL : womProdBaseURL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        productEffects.getProductContent(queryProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // * mock data
    if (!store[remoteProductId]?.items.length) return <div style={{ color: 'red' }} />;

    return (
        <Wrapper className={`wom-product-viewer-plugin  ${appVersion} ${isDev ? 'DEV' : ''}`}>
            <Main organizationPublicId={organizationPublicId} remoteProductId={remoteProductId} />
        </Wrapper>
    );
};
