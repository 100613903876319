import RemoveIcon from 'assets/icons/remove.svg';
import { flexStart } from 'constants/styles/mixins';
import styled from 'styled-components/macro';

export const StyledNotifications = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;

    // width: 380px;
    max-width: 100%;

    z-index: 99;
`;

export const Notification = styled.div`
    ${flexStart};
    flex-direction: column;
    width: 100%;
    padding: 16px 24px;
    margin-bottom: 10px;
    background-color: white;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    @keyframes show {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    animation: show 0.3s;
`;

// export const Header = styled.div`
//     display: flex;
// `;

export const Message = styled.div`
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 24px;
`;

export const Remove = styled.div`
    flex: none;

    width: 12px;
    height: 12px;

    background-image: url(${RemoveIcon});

    cursor: pointer;
`;

export const Description = styled.div`
    font-size: 14px;
    margin-top: 10px;
`;
