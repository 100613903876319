import scrollButton from 'assets/img/scrollButton.svg';
import { Notifications } from 'components/Notifications';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { VideoFooter } from 'components/common/videoComponents/VIdeoFooter';
import { VideoHeader } from 'components/common/videoComponents/VideoHeader';
import { MobileLoader } from 'components/dynamic/Loader';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { Row } from 'components/wrappers/FlexWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { useStore } from 'effector-react';
import Hls from 'hls.js';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { productEvents, productStores } from 'stores/product';
import { getClientHeight } from 'utils/common';
import {
    HashtagWrapper,
    HashtagsAbsoluteWrapper,
    InfoWrapper,
    ScrolledWrapper,
    VideoPlay,
    VideoPlayerWrapper
} from './style';

const { productContent } = productStores;
const { updateIsMuted } = productEvents;

const ScrolledBlock: FC = ({ children }) => {
    const scrolledBlockRef = useRef<HTMLDivElement>(null);
    const onScrollLeft = () => {
        if (!scrolledBlockRef.current) return;

        const scrolledBlock = scrolledBlockRef.current;

        scrolledBlock.scrollLeft -= 100;
    };

    const onScrollRight = () => {
        if (!scrolledBlockRef.current) return;

        const scrolledBlock = scrolledBlockRef.current;

        scrolledBlock.scrollLeft += 100;
    };

    return (
        <ScrolledWrapper ref={scrolledBlockRef}>
            <AbsoluteWrapper left="5px" top="5px" onClick={onScrollLeft}>
                <CustomImage height="20px" src={scrollButton} width="20px" />
            </AbsoluteWrapper>
            <AbsoluteWrapper right="5px" top="5px" onClick={onScrollRight}>
                <CustomImage height="20px" rotate={180} src={scrollButton} width="20px" />
            </AbsoluteWrapper>
            <Row noWrap>{children}</Row>
        </ScrolledWrapper>
    );
};

interface VideoPlayerProps {
    video: WOM.ContentResponse;
    isActive: boolean;
    remoteProductId: string;
    index: number;
    organizationPublicId: string;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({
    video,
    isActive,
    remoteProductId,
    index,
    organizationPublicId
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const { selector, items, color, textColor } = useStore(productContent)[remoteProductId];
    const height = useMemo(() => getClientHeight(selector), [selector]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [duration, setDuration] = useState(0);

    const setDurationVideo = (videoPlayer: HTMLVideoElement) => {
        videoPlayer.addEventListener('loadedmetadata', () => {
            setDuration(videoPlayer.duration);
        });
    };

    const handleMute = (index: number): void => {
        updateIsMuted({ remoteProductId, index });
    };

    useEffect(() => {
        if (!videoRef.current || !video?.streaming?.hlsUrl) return;
        const videoSrc = `${video.streaming.hlsUrl}?t=2&c=${video.id}&p=${remoteProductId}&o=${organizationPublicId}`;
        // t - type. 2 for Product Viewer
        const videoPlayer = videoRef.current;
        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(videoSrc);
            hls.attachMedia(videoPlayer);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                setIsLoading(false);
                videoPlayer.play();
                setDurationVideo(videoPlayer);
            });
        } else if (videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
            videoPlayer.src = videoSrc;
            videoPlayer.addEventListener('loadedmetadata', () => {
                videoPlayer.play();
                setDuration(videoPlayer.duration);
                // effects.setLoadedVideo(true);
            });
        }
    }, [organizationPublicId, remoteProductId, video]);

    // TODO: api
    return (
        <VideoPlayerWrapper>
            <RelativeWrapper height="100%" width="auto" onClick={() => handleMute(index)}>
                <Notifications />
                {!video ? (
                    <MobileLoader />
                ) : (
                    <>
                        <InfoWrapper>
                            <VideoHeader
                                duration={duration}
                                isLoading={isLoading}
                                isMuted={isActive && items[index].isMuted}
                                progressColor={color}
                                src={''}
                                username={'some'}
                            />
                            <VideoFooter />
                        </InfoWrapper>
                        <VideoPlay
                            ref={videoRef}
                            loop
                            playsInline
                            height={height}
                            muted={!(isActive && items[index].isMuted)}
                        />
                    </>
                )}
            </RelativeWrapper>
            <HashtagsAbsoluteWrapper>
                {video.tags && video.tags.length !== 0 ? (
                    <ScrolledBlock>
                        {video.tags.map(tag => (
                            <HashtagWrapper
                                key={tag}
                                backgroundColor={color}
                                textColor={textColor}
                            >{`#${tag}`}</HashtagWrapper>
                        ))}
                    </ScrolledBlock>
                ) : null}
            </HashtagsAbsoluteWrapper>
        </VideoPlayerWrapper>
    );
};
