import {
    usernameTextFontSize,
    usernameWrapperBorderRadius,
    usernameWrapperHeight
} from 'components/common/videoComponents/VideoHeader/constants';
import { flexStart } from 'constants/styles/mixins';
import styled from 'styled-components';
import { IconSoundProps } from 'types/styles';

export const UsernameWrapper = styled.div`
    ${flexStart};
    align-items: center;
    height: ${usernameWrapperHeight};
    border-radius: ${usernameWrapperBorderRadius};
    background: rgba(0, 0, 0, 0.3);
`;

export const UsernameText = styled.span`
    font-size: ${usernameTextFontSize};
    font-style: normal;
    font-weight: 600;
    line-height: ${usernameTextFontSize};
    letter-spacing: 0.21333329379558563px;
    text-align: right;
    color: white;
    margin-left: 8px;
    margin-right: 5px;
`;

export const IconSound = styled.div<IconSoundProps>`
    position: relative;
    background-color: rgba(51, 51, 51, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        content: '';
        background-image: url(${p => p.icon});
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        transform: translate(-50%, -50%);
    }
`;
