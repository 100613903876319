import React, { FC } from 'react';
import { Progress, ProgressVideoStyled } from './styles';

interface Props {
    duration: number;
    className?: string;
    isActiveVideo: boolean;
    progressColor?: string;
}

export const ProgressVideo: FC<Props> = ({ duration, className, isActiveVideo, progressColor }) => (
    <ProgressVideoStyled className={className}>
        <Progress duration={duration} isActiveVideo={isActiveVideo} progressColor={progressColor} />
    </ProgressVideoStyled>
);
