import SoundOffIcon from 'assets/video/sound-off.svg';
import SoundOnIcon from 'assets/video/sound-on.svg';
import { ProgressVideo } from 'components/common/videoComponents/ProgressVideo';
import { IconSound } from 'components/common/videoComponents/VideoHeader/styles';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import React from 'react';
import { ImgProperties } from 'types/data';

interface Props extends ImgProperties {
    username: string;
    isLoading?: boolean;
    isMuted?: boolean;
    duration: number;
    progressColor?: string;
}

// TODO: api
export const VideoHeader = ({ isLoading, isMuted, duration, progressColor }: Props) => (
    <Column width="100%">
        <Section marginBottom="23px">
            {!isLoading && <ProgressVideo isActiveVideo duration={duration} progressColor={progressColor} />}
        </Section>
        <Section alignCenter justifyBetween>
            <IconSound icon={isMuted ? SoundOnIcon : SoundOffIcon} />
            {/* <UsernameWrapper>
                <UsernameText>{username}</UsernameText>
                <AvatarImg hasBorder diameter={usernameWrapperHeight} src={src || defaultAvatar} />
            </UsernameWrapper> */}
        </Section>
    </Column>
);
