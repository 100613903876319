export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'https://wom-dev.xc.io/';

export const womDevBaseURL = 'https://wom-dev.xc.io/';
export const womProdBaseURL = 'https://api-v1.womprotocol.io/';

export const localeStandard = 'ISO639';
export const email = 'atleast24.7@list.ru';
export const password = 'Qwerty78';
export const userIdWithVideo = '20d2a16c36965c885fb9c8f7';

export const appVersion = process.env.REACT_APP_VERSION as string;
