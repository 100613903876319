import styled from 'styled-components';

export const MobileWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

interface ImgProps {
    height: string;
    width: string;
}

export const Img = styled.img<ImgProps>`
    width: ${({ width }) => (width ? width : 'auto')};
    height: ${({ height }) => (height ? height : '100%')};
    overflow: hidden;
`;
