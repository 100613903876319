import { Row } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { useStore } from 'effector-react';
import React from 'react';
import { notificationsEffects, notificationsStore } from 'stores/notifications';
import { Description, Message, Notification, Remove, StyledNotifications } from './styles';

export const Notifications = () => {
    const notifications = useStore(notificationsStore.notifications);

    if (notifications.length === 0) return null;

    return (
        <StyledNotifications>
            {notifications.map(({ message, description, id }) => (
                <Notification key={id}>
                    <Row alignCenter>
                        <Message>{message}</Message>
                        <MarginWrapper marginLeft="15px">
                            <Remove onClick={() => notificationsEffects.removeNotification(id)} />
                        </MarginWrapper>
                    </Row>
                    {description && <Description>{description}</Description>}
                </Notification>
            ))}
        </StyledNotifications>
    );
};
