import { lg_1 } from 'constants/styles/sizes';
import { css } from 'styled-components';

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexStart = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ellipsisMixin = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ellipsisRowMixin = css`
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
`;

export const disableDefaultButtonStyleMixin = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

export const disableDefaultInputStyleMixin = css`
    outline: none;
    appearance: none;
    border: none;
`;

export const scrollBarDesktopMixin = css`
    padding-bottom: 4px;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: grey;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 6px;
    }
`;

export const scrollBarMobileMixin = css`
    &::-webkit-scrollbar {
        width: 0px;
        /* height: 0; */
        background: transparent;
    }
    scrollbar-width: none;
`;

export const adaptiveScrollbarMixin = css`
    @media (min-width: ${lg_1}) {
        ${scrollBarDesktopMixin};
    }
    @media (max-width: ${lg_1}) {
        ${scrollBarMobileMixin};
    }
`;
