import { progressStyle } from 'components/common/videoComponents/ProgressVideo/constants';
import styled, { css } from 'styled-components';

export const ProgressVideoStyled = styled.div`
    ${progressStyle};
    width: 100%;
    background-color: rgba(151, 151, 151, 0.5);
    z-index: 1;
`;

interface Props {
    duration: number;
    isActiveVideo: boolean;
    progressColor?: string;
}

export const Progress = styled.div<Props>`
    ${progressStyle};
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${({ progressColor }) => progressColor || '#33ff6b'};
    transform: translateX(-100%);

    ${p =>
        p.isActiveVideo &&
        css`
            animation: progress ${p.duration}s linear;
            animation-iteration-count: infinite;
        `} @keyframes progress {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(0);
        }
    }
`;
