import { ellipsisMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const VideoProductText = styled.span`
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    ${ellipsisMixin};
`;

export const ProductInfoWrapper = styled.div`
    width: 100%;
    background: rgba(0, 0, 0, 0.58);
    padding: 7px;
`;

export const ProductInfoTitle = styled.span`
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    ${ellipsisMixin};
`;

export const ProductInfoSubtitle = styled.span`
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    ${ellipsisMixin};
`;
