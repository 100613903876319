import { createEffect, createStore } from 'effector';
import { API } from 'services';
import { CheckProductContentProps } from 'types/plugin';

//* checks on prod api
const checkProductContent = createEffect({
    handler: async ({ remoteProductId, organizationPublicId, selector }: CheckProductContentProps) => {
        try {
            const data = await API.product.checkProductContent({
                remoteProductId,
                organizationPublicId,
                pageIndex: 0,
                limit: 99
            });

            return { ...data, selector };
        } catch (err) {
            return { ...err.data, selector };
        }
    }
});

const checkProduct = createStore<any>({});

checkProduct.on(checkProductContent.doneData, (state, newState) => ({ ...state, ...newState }));

const checkEffects = { checkProductContent };
const checkStores = { checkProduct };

export { checkStores, checkEffects };
