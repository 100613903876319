import { prefix } from 'components/common/Hashtag/constants';
import { HashtagsWrapper, Wrapper } from 'components/common/Hashtag/styles';
import React, { FC } from 'react';

interface Props {
    removePrefix?: boolean;
}

export const Hashtag: FC<Props> = ({ removePrefix, children }) => (
    <Wrapper>
        {!removePrefix && prefix}
        {children}
    </Wrapper>
);

interface HashtagsProps {
    hashtags?: string[];
}

export const Hashtags = ({ hashtags }: HashtagsProps) => (
    <HashtagsWrapper>
        {hashtags?.map(i => (
            <Hashtag key={i}>{i}</Hashtag>
        ))}
    </HashtagsWrapper>
);
