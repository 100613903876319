import { createEvent, createStore } from 'effector';

const setHeight = createEvent<number>();
const setWidth = createEvent<number>();

const setHeightString = createEvent<string>();

const sizes = createStore([0, 0])
    .on(setHeight, (sizes, height) => [sizes[0], height])
    .on(setWidth, (sizes, width) => [width, sizes[1]]);

const sizesHeight = createStore('').on(setHeightString, (_, height) => height);

export const sizesStores = { sizes, sizesHeight };
export const sizesEvents = { setHeight, setWidth, setHeightString };
