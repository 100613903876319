import { VideoPlayer } from 'components/common/videoComponents/VideoPlayer';
import { MobileLoader } from 'components/dynamic/Loader';
import { useStore } from 'effector-react';
import { TProductIdProps, withAdaptiveComponent } from 'hocs/withAdaptiveComponent';
import React, { FC, useMemo, useState } from 'react';
import { productStores } from 'stores/product';
import SwiperCore, { Navigation, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperClass from 'swiper/types/swiper-class';
import { getClientHeight, getClientWidth } from 'utils/common';
import { Wrapper } from './styles';

const { productContent } = productStores;

// install Swiper modules
SwiperCore.use([Virtual, Navigation]);

export const MainComponent: FC<TProductIdProps> = ({ remoteProductId, organizationPublicId }) => {
    const { selector, items } = useStore(productContent)[remoteProductId];
    const height = useMemo(() => getClientHeight(selector), [selector]);
    const width = useMemo(() => getClientWidth(selector), [selector]);

    const videosCount = items?.length || 0;
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const handleChangeSlide = ({ activeIndex }: SwiperClass) => setActiveVideoIndex(activeIndex);

    return (
        <Wrapper height={height + 'px'} width={width + 'px'}>
            <Swiper
                direction="horizontal"
                navigation={videosCount > 1}
                simulateTouch={false}
                onSlideChangeTransitionEnd={handleChangeSlide}
            >
                {items?.map((video: any, i: number) => (
                    <SwiperSlide key={i.toString()}>
                        {activeVideoIndex === i ? (
                            <VideoPlayer
                                index={i}
                                isActive={activeVideoIndex === i}
                                organizationPublicId={organizationPublicId}
                                remoteProductId={remoteProductId}
                                video={video}
                            />
                        ) : (
                            <MobileLoader />
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </Wrapper>
    );
};

export const Main = withAdaptiveComponent<TProductIdProps>(MainComponent);
