import { womBaseURL } from 'constants/stores';
import axios from './axios';

export const getProductContent = (data: WOM.QueryProductViewerContentRequest) =>
    axios<WOM.QueryProductViewerContentResponse>({
        url: '/product/remote/query-content',
        baseURL: womBaseURL(),
        data
    });
export const checkProductContent = (data: WOM.QueryProductViewerContentRequest) =>
    axios<WOM.QueryProductViewerContentRequest>({
        url: '/product/remote/query-content',
        baseURL: womBaseURL(),
        data
    });
